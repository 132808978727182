<template>
  <div class="overview-page">
    <div class="tracking-block">
      <div class="left-col">
        <div class="col-header">
          <div class="title">Tracking Keywords</div>
        </div>
        <div class="tracking-keywords-table dashboard-tracking-keywords">
          <template v-if="trackingKeywordsLoaded">
            <tracking-keywords-dashboard-table :tracking-keywords-data="trackingKeywordsData"
                                               :per-page="showTrackingLoadMoreBtn ? 10 : 11"
                                               @show-all="showTrackingKeywordsModal = true"
                                               :show-tracking-load-more-btn="showTrackingLoadMoreBtn" />
          </template>
          <template v-else>
            <div class="skeleton-table">
              <data-table :headers="dashboardTrackedRecommendedHeaders"
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          :rows-per-page-items="[50, 100, 250, 500, 1000]"
                          :no-header="true"
                          :no-shadow="true"
                          :side-paddings="true"
                          :fixed-width="true"
                          :show-actions="false"
                          :with-show-more="false"
                          :pagination="pageMeta"
                          :sorting-meta="{sort_by: 'keyword',sort_direction: 'desc'}"
                          :items-per-page="+pageMeta.per_page"
                          :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}">

                <template v-slot:items="{item}">
                  <td v-for="headerItem in dashboardTrackedRecommendedHeaders"
                      :style="{width: headerItem.width}"
                      :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
                    <div class="inner-content">
                      <div class="skeleton long-table-skeleton-box"></div>
                    </div>
                  </td>
                </template>
              </data-table>
            </div>
          </template>
        </div>
      </div>

      <div class="right-col">
        <div class="col-header">
          <div class="title">Competitors</div>
          <router-link class="link" tag="a" :to="{name: 'CompetitorsFinder'}">See all</router-link>
        </div>
        <div class="competitors-block">
            <competitors ref="competitorsComponentRef" :current-country="currentCountry" :current-app="currentApp" @app-click="competitorAppClicked"></competitors>
        </div>
      </div>
    </div>

    <div class="growth-helper-block">
      <div class="block-heading">
        <div class="title">Growth Helper</div>
        <div class="sort-trigger"
             v-if="currentApp?.store?.key === 'APP_STORE'">
          <custom-dropdown :close-dropdown="currentSorting?.length">
            <template v-slot:header>
              <span class="sort-by">
                <span class="mobile-hidden">Sort by:</span>
                <span class="sort-word">{{ currentSorting === 'group' ? 'Category' : 'Priority' }}</span></span>
            </template>
            <template v-slot:content>
              <div class="select-options">
                <div class="option"
                     :class="{active: currentSorting === 'group'}"
                     @click="currentSortingChanged('group')">Category
                </div>
                <div class="option"
                     :class="{active: currentSorting === 'importance'}"
                     @click="currentSortingChanged('importance')">Priority
                </div>
              </div>
            </template>
          </custom-dropdown>
        </div>
      </div>
      <template v-if="currentApp?.store?.key === 'APP_STORE'">
        <div class="growth-block">

          <template v-if="insightsDataLoaded">
            <template v-if="insightsComputed !== null">
              <div class="alerts-block"
                   v-if="insightsComputed?.total_checked_plan_allowed !== insightsComputed?.total_checked">
                😢 <span
                  class="strong">{{
                  insightsComputed?.total_checked_plan_allowed
                }} out of {{ insightsComputed?.total_checked }}</span>
                alerts are available. <span
                  class="link-trigger"
                  @click="triggerLimitsModal">Get full access</span>
              </div>

              <div class="insight-bundle-block"
                   v-for="(item, index) in insightsComputed?.groupsObject">
                <div class="bundle-heading">
                  <div class="content-part">
                    <div class="icon-wrap"
                         :class="[{category: currentSorting === 'group', priority: currentSorting === 'importance'}, item[0]?.key]">
                      <svg-icon :icon="setInsightIcon(item[0]?.key)"/>
                    </div>
                    <div class="insight-title">{{ item[0]?.key }}</div>
                    <template
                        v-if="calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'found') !== calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'checked')">
                      <div class="insight-showing mobile-hidden">(Showing
                        {{ calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'found') }} Insights out of
                        {{ calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'checked') }})
                      </div>
                    </template>
                    <template
                        v-if="calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'found') !== calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'checked')">
                      <div class="insight-showing mobile-visible">
                        ({{ calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'found') }} of
                        {{ calculateInsightsGroupedFound(item[0]?.key, currentSorting, 'checked') }})
                      </div>
                    </template>
                  </div>
                  <div class="link-part mobile-hidden"
                       v-if="+index === 0 && (insightsComputed?.total_checked_plan_allowed !== insightsComputed?.total_checked)"
                       @click="triggerLimitsModal">See all insights
                  </div>
                </div>

                <insights-item v-for="insight in item"
                               :insight="insight"/>
              </div>
            </template>
            <template v-else>
              <div class="no-insights">No data found</div>
            </template>
          </template>
          <template v-else>
            <div class="preloader-wrap">
              <preloader-table-small loader-size="50px"></preloader-table-small>
            </div>
          </template>
        </div>

        <div class="btn-block"
             v-if="insightsComputed?.total_checked_plan_allowed < insightsComputed?.total_checked">
          <button class="btn more-insights"
                  @click="triggerLimitsModal">Show More Insights
          </button>
        </div>
      </template>
      <template v-else>
        <div class="empty-block">
          <div class="text">🧐 Coming Soon…</div>
        </div>
      </template>

    </div>

    <div class="compare-top-block">
      <div class="block-heading">
        <div class="title">Compare with Category Top <span class="hide-on-tablet">&nbsp;Apps</span></div>
        <div class="top-triggers-block">
          <button class="btn new-default"
                  :class="{active: compareCategoryAmount === 10}"
                  @click="changeTopCategory(10)">Top 10
          </button>

          <button class="btn new-default"
                  :class="{active: compareCategoryAmount === 50}"
                  @click="changeTopCategory(50)">Top 50
          </button>
        </div>
      </div>

      <div class="compare-table"
           v-if="!isMobile">

        <template v-if="compareCategoryDataLoaded">
          <data-table :headers="dashboardCompareHeaders"
                      :items="compareCategoriesData"
                      :no-shadow="true"
                      :side-paddings="true"
                      :with-show-more="true"
                      :show-more-count="6"
                      :show-more-colspan="4"
                      :rows-per-page-items="[50, 100, 250, 500, 1000]"
                      :fixed-width="true"
                      :show-actions="false"
                      :pagination="pageMeta"
                      :sorting-meta="{sort_by: 'keyword',sort_direction: 'desc'}"
                      :items-per-page="+pageMeta.per_page"
                      :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}">

            <template v-slot:items="{item}">
              <td v-for="headerItem in dashboardCompareHeaders"
                  :style="{width: headerItem.width}"
                  :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '', headerItem.headingClass]">
                <div class="inner-content">
                  <template v-for="(tdItem, tdIndex) in item"
                            :key="tdIndex">

                    <template v-if="tdIndex === headerItem.value">
                      <template v-if="headerItem?.value === 'key'">
                        <div class="block-content desktop-block-visible">
                          {{ tdItem }}
                        </div>
                        <div class="block-content desktop-block-hidden">
                          {{ item.mobileKey }}
                        </div>
                      </template>

                      <template v-if="headerItem?.value === 'application'">
                        <div class="block-content">
                          {{ tdItem }}
                        </div>
                      </template>

                      <template v-if="headerItem?.value === 'category'">
                        <div class="block-content">
                          {{ tdItem }}
                        </div>
                      </template>

                      <template v-if="headerItem?.value === 'compare'">
                        <div class="block-content">
                          <div class="difference-block"
                               :class="item.compare_color_class">

                            <div class="table-tooltip-block">
                              <div class="table-tooltip-trigger">{{ item.compare_percent }}</div>
                              <div class="table-tooltip-content">{{ tdItem }}</div>
                            </div>

                          </div>
                        </div>
                      </template>
                    </template>

                  </template>
                </div>
              </td>
            </template>
          </data-table>
        </template>
        <template v-else>
          <div class="skeleton-table">
            <data-table :headers="dashboardOverviewHeaders"
                        :items="[1,2,3,4,5,6]"
                        :rows-per-page-items="[50, 100, 250, 500, 1000]"
                        :no-header="true"
                        :no-shadow="true"
                        :side-paddings="true"
                        :fixed-width="true"
                        :show-actions="false"
                        :with-show-more="false"
                        :pagination="pageMeta"
                        :sorting-meta="{sort_by: 'keyword',sort_direction: 'desc'}"
                        :items-per-page="+pageMeta.per_page"
                        :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}">

              <template v-slot:items="{item}">
                <td v-for="headerItem in dashboardOverviewHeaders"
                    :style="{width: headerItem.width}"
                    :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
                  <div class="inner-content">
                    <div class="skeleton long-table-skeleton-box"></div>
                  </div>
                </td>
              </template>
            </data-table>
          </div>
        </template>
      </div>

      <div v-else class="compare-mobile-table-block">
        <template v-if="compareCategoryDataLoaded">
          <div class="compare-mobile-table">
            <div class="table-head">
              <div class="table-item">Metrics</div>
              <div class="table-item">Category Top</div>
            </div>

            <div class="table-body">
              <div class="table-row"
                   v-for="item in compareCategoriesData">
                <div class="table-item">
                  <div class="key">{{ item.mobileKey }}</div>
                  <div class="application">{{ item.application }}</div>

                  <div class="table-tooltip-block"
                       :class="item.compare_color_class">
                    <div class="table-tooltip-trigger">{{ item.compare_percent }}</div>
                    <div class="table-tooltip-content">{{ item.compare }}</div>
                  </div>

                </div>
                <div class="table-item category">{{ item.category }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="skeleton-table">
            <data-table :headers="dashboardOverviewHeaders"
                        :items="[1,2,3,4,5,6,7,8,9,10]"
                        :rows-per-page-items="[50, 100, 250, 500, 1000]"
                        :no-header="true"
                        :no-shadow="true"
                        :fixed-width="true"
                        :show-actions="false"
                        :with-show-more="false"
                        :pagination="pageMeta"
                        :sorting-meta="{sort_by: 'keyword',sort_direction: 'desc'}"
                        :items-per-page="+pageMeta.per_page"
                        :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}">

              <template v-slot:items="{item}">
                <td v-for="headerItem in dashboardOverviewHeaders"
                    :style="{width: headerItem.width}"
                    :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
                  <div class="inner-content">
                    <div class="skeleton long-table-skeleton-box"></div>
                  </div>
                </td>
              </template>
            </data-table>
          </div>
        </template>
      </div>

    </div>

    <custom-modal :opened="showTrackingKeywordsModal"
                  :max-width="728"
                  class-name="dashboard-tracking-modal"
                  @closeModal="closeModal('showTrackingKeywordsModal')">
      <div class="modal-title">Tracking Keywords</div>
      <div class="modal-message">
        <tracking-keywords-dashboard-table :tracking-keywords-data="trackingKeywordsData"
                                           @show-all="showTrackingKeywordsModal = true"
                                           :per-page="100"
                                           :show-tracking-load-more-btn="false" />
      </div>
    </custom-modal>

    <details-modal v-if="showAppDetailsModal"
                   :opened="showAppDetailsModal"
                   :app-data="clickedAppObject"
                   @competitor-changed="modalCompetitorChanged"
                   :change-competitor-in="true"
                   @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>

<!--    <the-improvement-block/>-->
  </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance, computed, watch} from 'vue';
import {httpRequest} from "@/api";
import {useStore} from 'vuex';
import {shortUnits, convertDays, cropImage, getCookie} from "@/utils/utils";
import ProgressBar from "@/components/UI/ProgressBar";
import DataTable from "@/views/DashboardPage/components/DataTable";
import {dashboardOverviewTableConfig} from "@/configs/page-tables-configs/dashboard-overview.js";
import {dashboardCompareTableConfig} from "@/configs/page-tables-configs/dashboard-compare.js";
import AppInfoTopBlock from "@/views/DashboardPage/components/AppInfoTopBlock";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import InsightsItem from "@/views/DashboardPage/components/InsightsItem";
import {dashboardTrackedRecommendedTableConfig} from "@/configs/page-tables-configs/dashboard-tracked-recommended";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import Competitors from "./Competitors/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import TrackingKeywordsDashboardTable from "@/views/DashboardPage/components/TrackingKeywordsDashboardTable/index.vue";

export default {
  name: "DashboardOverview",
  props: {
    currentKeywordCountryCode: {
      type: String,
    },
  },
    data() {
        return {
            clickedAppObject: {},
            showAppDetailsModal: false,
        }
    },
  components: {
    TrackingKeywordsDashboardTable,
    SimpleInfoTable,
    Competitors,
    ProgressBar,
    DataTable,
    AppInfoTopBlock,
    CustomDropdown,
    InsightsItem,
    DetailsModal,
  },
    methods: {
        competitorAppClicked(app) {
            this.clickedAppObject = app;
            this.showAppDetailsModal = true;
        },
        modalCompetitorChanged() {
            //competitor\non-competitor switcher event handler
            this.$refs.competitorsComponentRef.fetchCompetitorsData();
        }
    },
  setup() {
    const {proxy} = getCurrentInstance();
    let currentAppId = getCookie('currentAppId');
    let insightsData = ref({});
    let insightsListArray = ref([]);
    const apiUrls = proxy?.$ApiUrls;
    const dashboardTrackedRecommendedHeaders = dashboardTrackedRecommendedTableConfig.headers;
    const dashboardOverviewHeaders = dashboardOverviewTableConfig.headers;
    const dashboardCompareHeaders = dashboardCompareTableConfig.headers;
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);
    const pageMeta = computed(() => store.getters.pageMeta);
    const currentApp = computed(() => store.getters.currentApp);
    const currentCountry = computed(() => store.getters.currentCountry);
    const currentCountryManual = computed(() => store.getters.currentCountryManual);
    const promiseSettledData = computed(() => store.getters.promiseSettledData);
    const countriesList = computed(() => store.getters.countriesList);
    const insightsComputed = computed(() => {
      const tempInsightsData = {...insightsData.value};
      tempInsightsData.groupsObject = {...constructInsightsBySort(insightsListArray.value, currentSorting.value)};

      if (JSON.stringify(tempInsightsData?.groupsObject) === '{}') {
        return null;
      }

      return tempInsightsData;
    });

    let url = '';
    let showTrackingLoadMoreBtn = ref(false);
    let compareCategoryAmount = ref(50);
    let showTrackingKeywordsModal = ref(false);
    let compareCategoryDataLoaded = ref(false);
    let trackingKeywordsLoaded = ref(false);
    let insightsDataLoaded = ref(false);
    let isLoaded = ref(false);
    let currentSorting = ref('group');
    let trackingKeywordsData = ref([]);
    let trackingKeywordsShortenedData = ref([]);
    let compareCategoriesData = ref([]);
    let compareCategoryDataTop10Data = ref([]);
    let compareCategoryDataTop50Data = ref([]);
    let localesDataArray = ref([]);
    let countriesDataArray = ref([]);

    watch([currentCountryManual, currentApp, currentCountry], async function (newValues, oldValues) {
      if (JSON.stringify(newValues[0]) !== JSON.stringify(oldValues[0])) {
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + currentAppId;
        await resetPageValues(url);
      }

      if (newValues[1]?.id !== oldValues[1]?.id && oldValues[1]?.id) {
        currentAppId = newValues[1]?.id;
        url = '?country_code=' + newValues[2]?.code + '&app_id=' + newValues[1]?.id;
        await resetPageValues(url);
      }
    });

    if (Object.values(currentCountryManual.value)?.length === 0) {
      url = '?country_code=' + currentCountry?.value?.code + '&app_id=' + currentAppId;
    } else {
      url = '?country_code=' + currentCountryManual.value?.code + '&app_id=' + currentAppId;
    }

    async function resetPageValues(url) {
      compareCategoryDataLoaded.value = false;
      trackingKeywordsLoaded.value = false;

      trackingKeywordsShortenedData.value = [];
      trackingKeywordsData.value = [];
      compareCategoriesData.value = [];
      compareCategoryDataTop10Data.value = [];
      compareCategoryDataTop50Data.value = [];

      await fetchTrackingKeywordsData(url);
      await fetchInsightsData(url);
      await fetchCompareCategoryData(url);
    }

    function triggerLimitsModal() {
      store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    }

    function setInsightIcon(key) {
      const INSIGHT_ICONS = {
        'text': 'Text-icon',
        'reviews': 'Reviews-icon',
        'graphic': 'Graphic-icon',
        'other': 'Other-icon',
        'critical': 'Critical-icon',
        'medium': 'Recommendation-icon',
        'info': 'Objectives-icon',
      };

      return INSIGHT_ICONS[key];
    }

    function insightsTitlesConstruct(key) {
      const INSIGHTS_TITLES = {
        'short_title': 'Title is Too Short',
        'short_subtitle': 'Subtitle is Too Short',
        'empty_subtitle': 'Empty Subtitle',
        'duplications_of_locales': 'Duplicating Locales',
        'not_filled_locales': 'Not Filled Locales',
        'big_size': 'Size Is Much Higher, than Category Average',
        'rarely_update': 'Rate Updates',
        'few_screenshots': 'Less than 5 Screenshots',
        'few_rating': 'Low rating',
        'screenshots_locale_duplication': 'Duplicating Screenshots',
      };

      return INSIGHTS_TITLES[key] ?? key;
    }

    function insightsDescriptionConstruct(item) {
      const key = item?.insight;
      let currentAppValue;
      let currCategory;
      let currCategoryValue;
      let currAppPercentsMore;
      let locales;

      if (key === 'big_size') {
        currentAppValue = item?.additional_data?.diff_values?.app?.size_mb ?? '';
        currCategory = item?.additional_data?.diff_values?.category?.name ?? '';
        currCategoryValue = item?.additional_data?.diff_values?.category?.avg_size_mb ?? '';
        currAppPercentsMore = item?.additional_data?.diff_percent;
      }

      if (key === 'rarely_update') {
        currentAppValue = item?.additional_data?.diff_values?.app?.days ?? '';
        currCategory = item?.additional_data?.diff_values?.category?.name ?? '';
        currCategoryValue = item?.additional_data?.diff_values?.category?.avg_days ?? '';
        currAppPercentsMore = item?.additional_data?.diff_percent;
      }

      if (key === 'not_filled_locales') {
        locales = item?.additional_data?.not_filled_locales?.length ?? '';
      }

      const INSIGHTS_DESCRIPTIONS = {
        'short_title': 'The title is too short. You can use up to 30 characters for your app title. We recommend following this structure: \'Your brand name - keyword, keyword, keyword\'.',
        'short_subtitle': 'Subtitle is too short. You can use up to 30 characters for your subtitle. We recommend to use additional keywords in text.',
        'empty_subtitle': 'The subtitle has not been filled in. Fill in the subtitle with keywords not used in the title.',
        'duplications_of_locales': 'Duplicate locales were found. Get more installs around the world by optimizing the store metadata for different languages.',
        'not_filled_locales': `There are ${locales} locales that are not filled. This affects how your application appears for keywords and how many installs it receives.`,
        'big_size': `Top Applications in the ${currCategory} category average size is ${currCategoryValue}MB. ${currentAppValue}MB is the size of your application. The size of your application is ${currAppPercentsMore}% larger.`,
        'rarely_update': `${currCategory} are updated every ${currCategoryValue} days on average. On average, your app is updated every ${currentAppValue} days. You should update your app more often and improve the user experience.`,
        'few_screenshots': 'Screenshots are used to demonstrate your application\'s benefits and features. We recommend using at least five screenshots. App installs are influenced by the quality of screenshots.',
        'few_rating': 'Ratings play a significant role in App Store optimization and effects app visibility.',
        'screenshots_locale_duplication': 'Duplicating Screenshots',
      };


      return INSIGHTS_DESCRIPTIONS[key] ?? key;
    }

    function showInsightLocales(insight) {
      const SHOW_LOCALES_ARRAY = ['short_title', 'short_subtitle', 'duplications_of_locales', 'not_filled_locales'];

      return SHOW_LOCALES_ARRAY?.includes(insight);
    }

    function constructInsightLocalesList(item) {
      let localesArray = [];

      if (item?.insight === 'short_title') {
        localesArray = Object?.keys(item?.additional_data?.short_titles_by_locale) ?? [];
      }

      if (item?.insight === 'short_subtitle') {
        localesArray = Object?.keys(item?.additional_data?.short_sub_titles_by_locale) ?? [];
      }

      if (item?.insight === 'not_filled_locales') {
        localesArray = [...item?.additional_data?.not_filled_locales];
      }

      if (item?.insight === 'duplications_of_locales') {
        localesArray = item?.additional_data?.groups?.flat(1);
      }

      let localesString = '';
      let localesResultArray = [];

      localesArray.forEach(locale => {
        const splittedCode = locale.split('_');
        let localeItem = localesDataArray?.value?.find(innerLocale => innerLocale?.code === locale?.toLowerCase());
        if (splittedCode?.length > 1) {
          localeItem = localesDataArray?.value?.find(innerLocale => innerLocale?.code === splittedCode[0]?.toLowerCase());
        }

        if (localeItem) {
          if (splittedCode?.length > 1) {
            const countryExtention = countriesDataArray?.value?.find(innerLocale => innerLocale?.code?.toLowerCase() === splittedCode[1]?.toLowerCase());
            localesString += localeItem?.name + ' (' + countryExtention?.name + ')' + ', ';
            localesResultArray.push(localeItem?.name + ' (' + countryExtention?.name + ')');
          } else {
            localesString += localeItem?.name + ', ';
            localesResultArray.push(localeItem?.name);
          }
        }
      });

      if (localesResultArray) {
        localesResultArray.sort();
      }

      let formattedString = localesString.slice(0, -2);
      formattedString += '.';

      // return formattedString;
      return localesResultArray;
    }

    async function fetchTrackingKeywordsData(url) {
      trackingKeywordsLoaded.value = false;

      const result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + apiUrls.dashboard.TRACING_KEYWORDS_DATA + url
      );
      const countriesData = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.user.FILTER_COUNTRIES);
      let trackingKeywordsArray = [];
      let recommendedKeywordsArray = [];
      let rankingKeywordsArray = [];

      if (!result.hasOwnProperty('errors') && JSON.stringify(result) !== '{}' && JSON.stringify(result) !== '[]') {
        for (const key in result) {
          const country = countriesData.find(item => item?.code?.toLowerCase() === key.toLowerCase());

          if (!result[key]?.count_recommended && !result[key]?.count) {
            continue;
          }

          if (result[key]?.count !== 0) {
            trackingKeywordsArray.push({
              country: key,
              countryName: country?.name,
              countryId: country?.id,
              count: result[key]?.count,
              count_recommended: result[key]?.count_recommended,
              count_ranking: result[key]?.count_ranking,
              dynamic: result[key]?.dynamic,
              dynamic_recommended: result[key]?.dynamic_recommended,
              dynamic_ranking: result[key]?.dynamic_ranking,
            });
          } else if (result[key]?.count_recommended !== 0) {
            recommendedKeywordsArray.push({
              country: key,
              countryName: country?.name,
              countryId: country?.id,
              count: result[key]?.count,
              count_recommended: result[key]?.count_recommended,
              count_ranking: result[key]?.count_ranking,
              dynamic: result[key]?.dynamic,
              dynamic_recommended: result[key]?.dynamic_recommended,
              dynamic_ranking: result[key]?.dynamic_ranking,
            });
          } else if (result[key]?.count_ranking !== 0) {
            rankingKeywordsArray.push({
              country: key,
              countryName: country?.name,
              countryId: country?.id,
              count: result[key]?.count,
              count_recommended: result[key]?.count_recommended,
              count_ranking: result[key]?.count_ranking,
              dynamic: result[key]?.dynamic,
              dynamic_recommended: result[key]?.dynamic_recommended,
              dynamic_ranking: result[key]?.dynamic_ranking,
            });
          }
        }
      }

      trackingKeywordsArray.sort((a, b) => (a.count < b.count) ? 1 : -1);
      recommendedKeywordsArray.sort((a, b) => (a.count_recommended < b.count_recommended) ? 1 : -1);
      rankingKeywordsArray.sort((a, b) => (a.count_ranking < b.count_ranking) ? 1 : -1);

      trackingKeywordsData.value = [...trackingKeywordsArray, ...recommendedKeywordsArray, ...rankingKeywordsArray];

      trackingKeywordsShortenedData.value = [...trackingKeywordsData.value];
      trackingKeywordsShortenedData.value = trackingKeywordsShortenedData?.value?.splice(0, 10);

      showTrackingLoadMoreBtn.value = trackingKeywordsData.value?.length > 11;

      trackingKeywordsLoaded.value = true;
    }

    async function fetchInsightsData(url) {
      insightsDataLoaded.value = false;

      localesDataArray.value = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.user.FILTER_LOCALES);
      countriesDataArray.value = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.user.FILTER_COUNTRIES);

      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.dashboard.INSIGHTS_DATA + url);

      if (result?.insights_list) {
        insightsListArray.value = [...result?.insights_list];
      }

      insightsData.value = {
        total_checked: result?.total_checked,
        total_checked_plan_allowed: result?.total_checked_plan_allowed,
        total_found_by_group: {...result?.total_found_by_group},
        total_checked_by_group_plan_allowed: {...result?.total_checked_by_group_plan_allowed},
        total_found_by_group_plan_allowed: {...result?.total_found_by_group_plan_allowed},
        total_found_by_importance_plan_allowed: {...result?.total_found_by_importance_plan_allowed},
        total_found_by_importance: {...result?.total_found_by_importance},
        total_checked_by_importance_plan_allowed: {...result?.total_checked_by_importance_plan_allowed},
        insights_list: result?.insights_list,
      };

      insightsDataLoaded.value = true;
    }

    function currentSortingChanged(sortType) {
      currentSorting.value = sortType;
    }

    function constructInsightsBySort(array, sortType) {
      let groupsObject = {};
      array?.forEach(item => {
        if (!item?.check_result) {
          return;
        }

        if (item[sortType]) {
          if (!groupsObject[item[sortType]]) {
            groupsObject[item[sortType]] = [];
          }

          groupsObject[item[sortType]].push({
            ...item,
            key: item[sortType],
            sortWeight: calculateSortWeight(currentSorting.value === 'group' ? item?.group : item?.importance),
            title: insightsTitlesConstruct(item?.insight),
            description: insightsDescriptionConstruct(item),
            show_locales: showInsightLocales(item?.insight),
            locales: constructInsightLocalesList(item),
          });
        }
      });

      let groupsArray = Object.values(groupsObject);
      groupsArray.sort((a, b) => (a[0]?.sortWeight > b[0]?.sortWeight) ? -1 : 1);

      return groupsArray;
    }

    function calculateInsightsGroupedFound(key, sorting, type) {
      if (sorting === 'group') {
        if (type === 'found') {
          return insightsData.value?.total_found_by_group[key] ?? '';
        } else {
          return insightsData.value?.total_found_by_group_plan_allowed[key] ?? '';
        }
      } else {
        if (type === 'found') {
          return insightsData.value?.total_found_by_importance[key] ?? '';
        } else {
          return insightsData.value?.total_found_by_importance_plan_allowed[key] ?? '';
        }
      }
    }

    function calculateSortWeight(key) {
      const SORT_TITLE_WEIGHTS = {
        'text': 5,
        'reviews': 4,
        'graphic': 3,
        'other': 1,
        'critical': 5,
        'medium': 4,
        'info': 3,
      };

      return SORT_TITLE_WEIGHTS[key];
    }

    async function sendComparePromisesBundle() {
      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + apiUrls.dashboard.COMPARE_CATEGORY_BLOCK + url + '&amount=10',
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + apiUrls.dashboard.COMPARE_CATEGORY_BLOCK + url + '&amount=50',
          method: 'GET',
        },
      ];

      await store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);

      const top10Data = promiseSettledData.value?.data[0]?.value;
      const top50Data = promiseSettledData.value?.data[1]?.value;

      if (promiseSettledData.value.success && top10Data && top50Data) {

        compareCategoryDataTop10Data.value = constructCompareTable(top10Data);
        compareCategoryDataTop50Data.value = constructCompareTable(top50Data);
      }
    }

    async function fetchCompareCategoryData() {
      compareCategoryDataLoaded.value = false;
      await sendComparePromisesBundle();
      if (compareCategoryAmount.value === 10) {
        compareCategoriesData.value = [...compareCategoryDataTop10Data.value];
      } else {
        compareCategoriesData.value = [...compareCategoryDataTop50Data.value];
      }

      compareCategoryDataLoaded.value = true;
    }

    function constructCompareTable(result) {
      const KEY_CONSTS = {
        "installs": "Installs",
        "rating": "Rating",
        "voted": "Number of Ratings (Voted)",
        "age": "App Age",
        "price_usd": "Price",
        "iap": "In-app Purchases Price",
        "update_frequency_days": "Update Frequency",
        "title_length": "Title Length",
        "subtitle_length": currentApp.value?.store?.key === 'APP_STORE' ? "Subtitle Length" : "Short Description Length",
        "description_length": "Description Length",
        "screenshots_count": "Number of Screenshots",
        "has_video": "Has Video?",
        "size_mb": "Size"
      };
      const KEY_CONSTS_MOBILE = {
        "installs": "Installs",
        "rating": "Rating",
        "voted": "Voted",
        "age": "App Age",
        "price_usd": "Price",
        "iap": "IAP",
        "update_frequency_days": "Upd. Frequency",
        "title_length": "Title Length",
        "subtitle_length": currentApp.value?.store?.key === 'APP_STORE' ? "Subtitle Length" : "Short Desc. Length",
        "description_length": "Desc. Length",
        "screenshots_count": "Screenshots",
        "has_video": "Has Video?",
        "size_mb": "Size"
      };

      let tempArray = [];

      for (const key in result) {
        if (key === 'status') {
          continue;
        }

        if (key === 'installs' && currentApp.value?.store?.key === 'APP_STORE') {
          continue;
        }

        if (key === 'has_video') {
          continue;
        }

        if (key === 'price_usd' && result[key]?.application === 0) {
          continue;
        }

        if (KEY_CONSTS[key]) {
          tempArray.push({
            key: KEY_CONSTS[key],
            mobileKey: KEY_CONSTS_MOBILE[key],
            ...constructCompareTableItems(key, result[key])
          });
        }
      }

      return tempArray;
    }

    function constructCompareTableItems(key, itemValue) {
      let tempValue = {
        application: constructCompareItemValue(key, itemValue.application, 'first'),
        category: constructCompareItemValue(key, itemValue.category, 'medium'),
        compare: itemValue.application !== 0 ? constructCompareItemValue(key, itemValue.compare, 'last') : '',
        compare_percent: constructComparePercentsValue(key, itemValue.compare_percent),
        compare_color_class: constructCompareColorClass(key, itemValue.compare),
      };

      if (key === 'has_video') {
        tempValue = {
          application: itemValue.application > 0 ? 'Yes' : 'No',
          category: itemValue.category + '% has videos',
          compare: '',
          compare_percent: '',
        };
      }

      return tempValue;
    }

    function constructCompareItemValue(key, itemValue, type) {
      let value = itemValue;
      let absValue = Math.abs(itemValue);
      let mathSign = '';

      if (type === 'last') {
        if (itemValue > 0) {
          mathSign = '+'
        } else if (itemValue < 0) {
          mathSign = '-';
        }
      }

      if (key === 'rating') {
        value = absValue.toFixed(2);
      }

      if (key === 'age' && type !== 'last') {
        value = convertDays(absValue);
      }

      if (key === 'age') {
        value = convertDays(absValue);
      }

      if (key === 'update_frequency_days') {
        value = Math.round(absValue) + 'd';
      }

      if (key === 'voted' || key === 'installs') {
        value = shortUnits(absValue, 1);
      }

      if (key === 'size_mb') {
        value = absValue.toFixed(0) + 'MB';
      }

      if (key === 'title_length' || key === 'subtitle_length' || key === 'description_length') {
        value = absValue.toLocaleString('ru-RU', {maximumFractionDigits: 0});
      }

      if (key === 'price_usd' || key === 'iap') {
        if (absValue !== 0) {
          value = '$' + absValue.toLocaleString('en-US', {maximumFractionDigits: 0});
        } else {
          value = '';
        }
      }

      if (key === 'screenshots_count') {
        value = absValue.toFixed(0);
      }

      return `${mathSign}${value}`;
    }

    function constructComparePercentsValue(key, itemValue) {
      let value = itemValue;
      let absValue = Math.abs(itemValue);
      let mathSign = '';

      if (itemValue > 0) {
        mathSign = '+'
      } else if (itemValue < 0) {
        mathSign = '-';
      }

      if (absValue > 0 && absValue < 1) {
        value = absValue.toFixed(2);
      } else {
        value = absValue.toLocaleString('ru-RU', {maximumFractionDigits: 0});
      }

      if (absValue === 0) {
        value = '';
      } else {
        value += '%';
      }

      return `${mathSign}${value}`;
    }

    function constructCompareColorClass(key, itemValue) {
      let compareColorClass = '';

      if (key === 'rating') {
        if (itemValue > 0) {
          compareColorClass = 'green';
        } else if (itemValue < 0) {
          compareColorClass = 'red';
        }
      }

      if (key === 'update_frequency_days' || key === 'iap' || key === 'size_mb') {
        if (Math?.round(itemValue) > 0) {
          compareColorClass = 'red';
        } else if (Math?.round(itemValue) < 0) {
          compareColorClass = 'green';
        }
      } else if (key === 'rating') {
        if (itemValue > 0) {
          compareColorClass = 'green';
        } else if (itemValue < 0) {
          compareColorClass = 'red';
        }
      } else {
        if (Math?.round(itemValue) > 0) {
          compareColorClass = 'green';
        } else if (Math?.round(itemValue) < 0) {
          compareColorClass = 'red';
        }
      }

      return compareColorClass;
    }

    function changeTopCategory(amount) {
      compareCategoryAmount.value = amount;
      if (amount === 10) {
        compareCategoriesData.value = [...compareCategoryDataTop10Data.value];
      } else {
        compareCategoriesData.value = [...compareCategoryDataTop50Data.value];
      }
    }

    onMounted(async function () {
      await fetchTrackingKeywordsData(url);
      await fetchInsightsData(url);
      await fetchCompareCategoryData(url);
    });

    return {
      isLoaded,
      showTrackingKeywordsModal,
      showTrackingLoadMoreBtn,
      trackingKeywordsLoaded,
      compareCategoryDataLoaded,
      insightsDataLoaded,
      dashboardOverviewHeaders,
      dashboardCompareHeaders,
      dashboardTrackedRecommendedHeaders,
      changeTopCategory,
      currentSortingChanged,
      setInsightIcon,
      triggerLimitsModal,
      calculateInsightsGroupedFound,
      cropImage,
      trackingKeywordsShortenedData,
      trackingKeywordsData,
      compareCategoriesData,
      insightsComputed,
      compareCategoryAmount,
      pageMeta,
      isMobile,
      currentApp,
      currentCountry,
      currentSorting,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>